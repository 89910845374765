import hasFeature from "../utility/hasFeature";
import isPublicUrl from "../utility/isPublicUrl";
import consumer from "../../channels/consumer"

const registerMessagesChannel = () => {

  const body = document.querySelector('body');
  const script = document.querySelector('#script-body');

  if (!body || !script) {
    return;
  }

  const { user, origin } = body.dataset;
  const { scriptId } = script.dataset;


  const channel = consumer.subscriptions.create("MessagesChannel", {
    connected() {
      console.log("Connected to messages channel");
    },

    disconnected() {
      console.log("Disconnected from messages channel");
    },

    received(data) {
      console.log("Received message:", data);
      // Handle received messages here
    }
  });

  window.state = window.state || {};

  window.Remote = {
    action: (action) => {
      if (window.state.timer) window.state.timer.resetInterval();

      const excludedActions = ['initiator', 'fetch-script', 'device-width', 'device-num-lines'];
      if (!excludedActions.some(term => action.includes(term)) && !window.state.initiator) {
        return;
      }

      if (!hasFeature('sync_devices') && !isPublicUrl()) return;

      if (isPublicUrl() && !excludedActions.some(term => action.includes(term))) return;

      console.log(`Sending action: ${action}`);

      channel?.perform('remote_action', {
        "remote_action": action,
        "user_id": user,
        "script_id": scriptId,
        "origin": origin
      });
    }
  };
};

export default registerMessagesChannel;
